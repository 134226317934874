<template>
	<div class="container gal">

		<imgMetaEditor v-if="imgMetatDat.visible" :par="imgMetatDat"></imgMetaEditor>
		<vir-dialog v-if="dialog.visible" :par="dialog"></vir-dialog>
		<imgImport  v-if="impImg.visible" :par="impImg"></imgImport>








		<div style="margin: 1rem"><h2>Schaugarten Editor</h2></div>

		<div class="header">
			<div class="newItem"><button @click="createNewImg()"> neues Bild </button></div>

		</div>



			<draggable	 v-if="galDat" class="galList"v-model="galDat"	 handle=".sort" :disabled="false"  @end="orderChanged()" >
				<div v-for="(v,i) in galDat" class="item"> 

					<div class="title" :title="v.title">{{ v.title }}</div>

					<div  class="icon sort" title="sortieren"><svg @click=""><use xlink:href="/sys_img/icons.svg#orderIcon"/></svg></div>

					<div> <img :src="$store.state.server + '/img/sg/' + v.img_thumbnail " width="100" title="Bild wechseln" @click="importImg(i)"> </div>

					<div class="icon visible">
						<div title="sichtbar" v-if="v.visible == 1"><svg class="iVis"  @click="toggleItemVis(i)"><use xlink:href="/sys_img/icons.svg#visible"/></svg></div>
						<div title="nicht sichtbar" v-else ><svg class="iUvis" @click="toggleItemVis(i)"><use xlink:href="/sys_img/icons.svg#unvisible"/></svg></div>
					</div>

					<div title="Metadaten eingeben" class="icon iEdit"><svg @click="changeImgMetaDat(i)" ><use xlink:href="/sys_img/icons.svg#edit"/></svg></div>

					<div title="löschen" class="icon iDel"><svg @click="delGalItem(i)"><use xlink:href="/sys_img/icons.svg#delete"/></svg></div>

				</div>
			</draggable>




	</div>
</template>

<script>
	// import  virDialog from "@/components/Dialog.vue";
	// import  neuePflanze from "@/components/NeuePflanze.vue";
	import  imgImport from "@/components/GalImgImport.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	import  imgMetaEditor from "@/components/ImgMetaEditor.vue";
	import  virDialog from "@/components/Dialog.vue";
	import draggable from 'vuedraggable';

	export default {
		name: 'SchaugartenList',
		components: {
			imgMetaEditor,
			virDialog,
			imgImport,
			draggable,
		},
		mounted(){
			// console.log(tools.inputToFloat("12,2"));

			this.getIndex();
			// this.writeNewImgWithMetaDat();

		}, // end mounted 
		data: 
		function(){
			return {
				galDat: null,
				imgMetatDat: {visible: false, data: {}},
				dialog: {visible:false, data: {}, callback: null},
				impImg: {visible: false, data: {tn: null, full: null}, callback: null},
				tl: tools,

			}
		}, // end data
		methods: {

			getIndex(){
				let self = this;
				let url = this.$store.state.server + '/be/getSGIndex.php';



			axios.post(url, {
				crossDomain: true,
				headers: {
				},
				data: { kat: this.selKat},
			})
			.then(function (resp) {
				self.galDat = resp.data.data[0];

				});
			},

			orderChanged(){

				let self = this;
				let url = this.$store.state.server + '/be/changeSGOrder.php';


				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { o: this.galDat },
				})
				.then(function (resp) {
					// console.log(resp.data.data);
				});
			},

			createNewImg(){

				let self = this;
				this.impImg.callback = function(p){

					if (p === 1) {

						self.cerateNewImgMetaDat();

					}

					self.impImg.visible = false;
				};

				this.impImg.visible = true;

			},

						cerateNewImgMetaDat(key){

				this.imgMetatDat.data = {};


				let self = this;
				this.imgMetatDat.callback = function(p){


					if (p === 1) {

						self.writeNewImgWithMetaDat();

					}
					self.imgMetatDat.visible = false;
				};

				this.imgMetatDat.visible = true;
			},
			writeNewImgWithMetaDat(){

				let self = this;
				let url = this.$store.state.server + '/be/writeNewSGImg.php';



				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { 
						title: this.imgMetatDat.data.title, 
						alt_text: this.imgMetatDat.data.alt_text, 
						thumbNail: this.impImg.data.tn, 
						fullImg: this.impImg.data.full,
					},
				})
				.then(function (resp) {

					self.galDat = resp.data.data[0];
					self.$router.go();


				});


			},

			importImg(key){
				

				let self = this;
				this.impImg.callback = function(p){

					if (p === 1) {


						self.changeImg(key);




					}

					self.impImg.visible = false;
				};


				this.impImg.visible = true;




			},
						changeImg(key){

				let self = this;
				let url = this.$store.state.server + '/be/changeSGImg.php';


				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { item: this.galDat[key], thumbNail: this.impImg.data.tn, fullImg: this.impImg.data.full },
				})
				.then(function (resp) {


					// console.log(resp.data.data);

					self.galDat = resp.data.data;

					self.$router.go();
					// windows.history.go();
					// self.$forceUpdate();


				});


			},

			delGalItem(key){


				let tag = 'Soll der Eintrag  gelöscht werden?';




				this.dialog.data = {btn1: 'Abbrechen', btn2: 'OK', title: 'Löschen', text: tag};

				let self = this;
				this.dialog.callback = function(p){


					if (p === 1) {
						// let self = this;

						let url = self.$store.state.server + '/be/deleteSGItem.php';


						axios.post(url, {
							crossDomain: true,
							headers: {
							},
							data: { o: self.galDat[key]},
						})
						.then(function (resp) {

							self.galDat = resp.data.data;


						});
					}
					self.dialog.visible = false;
				};

				this.dialog.visible = true;



			},	

			toggleItemVis(key){


				let self = this;
				let url = this.$store.state.server + '/be/toggleSGImgVis.php';


				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { id: self.galDat[key].id, vis: self.galDat[key].visible },
				})
				.then(function (resp) {

					let id = resp.data.data[0];
					let vis = resp.data.data[1];

					self.galDat[key].visible = vis;
				});

			},

			changeImgMetaDat(key){

				this.imgMetatDat.data = this.galDat[key];


				let self = this;
				this.imgMetatDat.callback = function(p){



					if (p === 1) {

						self.saveGalMetaDat(self.imgMetatDat.data);

					}
					self.imgMetatDat.visible = false;
				};

				this.imgMetatDat.visible = true;
			},
						saveGalMetaDat(o){

				let self = this;
				let url = this.$store.state.server + '/be/changeSGMetaDat.php';


				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { o: o },
				})
				.then(function (resp) {


					if (resp.data.data[0]) {
						o.title = resp.data.data[1];
						o.alt_text = resp.data.data[2];
					}

				});
			},

		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

div.galList {
	display: flex;

	// height: 47rem;
	// overflow: auto;
	flex-wrap: wrap;

	& > div.item {
		flex: 0 0 12%;
		display: flex;
		align-items: center;
		position: relative;
		margin-top: 2rem;
		max-height: 6rem;
		& > div {
			margin-right: .2rem;
		}
		& div.title {
			position: absolute;
			top: -1.6rem;
			left: 2.2rem;
			height: 1.5rem;
			width: 14rem;
			text-align: left;
			overflow: hidden;
		}
		& div img {
				border: 1px solid silver;
		}
	}
}

.header {
	display: flex;
	margin: .4rem 0;
	& div {
	// background-color: #EEE472;
		flex: 0 0 25%;
	}
}

.icon {
	& svg {
		width: 1.6rem;
		height: 1.6rem;
	}
}
.iVis {
	stroke: #72b579;
}
.iUvis {
stroke: black;
}

.iEdit {
	fill: #7da481;
}
.iRen {
	fill: #a6b3a8;
}
.iDel {
	fill: #8a3131;
}

img, .icon, button, {
	cursor: pointer;
}


</style>
